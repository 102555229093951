import React from 'react'
import { FaTwitter, FaYoutube } from 'react-icons/fa'

const SocialMedia = ({ youtubeAlert }) => (
	<div id="home-social-media" className="home-shortcut">
		<a
			href="https://twitter.com/NivoSistem"
			target="_blank"
			rel="noopener noreferrer"
			id="twitter-btn"
			className="social-media">
			<FaTwitter className="social-media-icon" />
		</a>
		<div id="social-media-slider">
			<button
				id="youtube-btn"
				className="social-media"
				onClick={() => alert(youtubeAlert)}
				style={{ cursor: 'pointer' }}>
				<FaYoutube className="social-media-icon" style={{ top: '25%' }} />
			</button>
		</div>
	</div>
)

export default SocialMedia

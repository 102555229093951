import React from 'react'
import { Button } from 'react-bootstrap'
import { FaFilePdf, FaFile } from 'react-icons/fa'

const File = ({ file }) => (
	<Button
		href={file.source}
		target="_blank"
		size="lg"
		variant="outline-danger"
		className="file-download-btn shadow-none disable-focus-outline">
		{file.fileType === 'pdf' ? <FaFilePdf /> : <FaFile />}
		{` ${file.name}`}
	</Button>
)

export default File

import React from 'react'
import ImageZoom from 'react-medium-image-zoom'

const Machine = ({ machine }) => (
	<div style={{ fontSize: '1.25em' }}>
		<h2>{machine.name}</h2>
		<p>{machine.description}</p>
		{machine.pics.map(pic => (
			<ImageZoom
				key={machine.name}
				image={{
					src: pic,
					alt: machine.name,
					style: { width: '100%', marginBottom: 10 }
				}}
			/>
		))}
	</div>
)

export default Machine

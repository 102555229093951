import React from 'react'

const Shortcut = ({ shortcut, history }) => (
	<div
		id={`home-${shortcut.id}`}
		className="home-shortcut"
		onClick={() => history.push(`/${shortcut.id}`)}>
		<div
			className="home-shortcut-child"
			style={{
				backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.75)), url(${
					shortcut.image
				})`
			}}>
			<div>
				<h3>{shortcut.title}</h3>
				<p>{shortcut.subtext}</p>
			</div>
		</div>
	</div>
)

export default Shortcut

import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'

const resources = {
	eng: {
		translation: {
			otherLanguage: 'Srpski',
			home: 'Home',
			machinesAndGadgets: 'Machines and Equipment',
			news: 'News',
			contact: 'Contact',
			homeHeaderTypedString1:
				'Experience^800, dedication^800, reliability.^800',
			homeHeaderTypedString2: 'The brand.',
			aboutUsTitle: 'About us',
			aboutUsText1:
				'Nivo Sistem is a company dedicated to the installing, servicing, maintaining machines and other equipment in the woodworking industry.',
			aboutUsText2:
				'Our staff consists of two people, both with years of experience in the maintenance of woodworking machines and the newest technologies. We do the assembling, the regular and unscheduled servicing of edge-bender machines, panel sizing equipment, CNC centers, exhausting systems, and all other types. We have experience with machines of the following brands:',
			aboutUsText3:
				'We pay high attention to the detailed montage of the machines, and their complete, professional description and tutorial. The well-assembled, properly maintained machine and the perfectly trained worker guarantees you long-time quality processing and low usage costs.',
			aboutUsText4: 'Contact us',
			aboutUsText5: 'and experience our quality work.',
			machinesAndGadgetsSubtext:
				'Take a look at our selection of new machines and request an offer.',
			newsSubtext: "See what's new.",
			contactSubtext: 'In case you have a question, feel free to contact us.',
			partners: 'Our partners:',
			usedMachines: 'Available used machines',
			noUsedMachinesAvailable: 'Currently none.',
			altendorfDescription:
				'The story of the success of Altendorf is the story of the success of a machine: the sliding table saw invented in 1906 by Wilhelm Altendorf. Kurt and Willy Altendorf, the founder\'s sons, decided in 1956 to focus exclusively on manufacturing sliding table saws according to the "Altendorf system", turning Altendorf into a specialist and setting the Altendorf sliding table saw on course to conquer the world. The number of Altendorf sliding table saws put to work in carpentry, cabinet-making and joinery workshops and wood, metal and plastics processing companies around the world has now surpassed 150,000. Thanks to their products\' quality and their international expansion strategy, the Altendorf name is now recognised as synonymous with the sliding table saw in more than 130 countries around the world.',
			hebrockDescription:
				'Founded in 1980, in Eastern Westphalia – which is considered to be the beating heart of the German furniture industry –, the family business Hebrock is a top manufacturer specializing in edge banding machines. From their compact and robust “Top 2000 plus” starter model to their complete and fully equipped "K series" models, the Hebrock edge banders are able to satisfy the highest industrial requirements for large, medium-sized and small companies alike. As of 2018, after a long, neigbourly relationship with Altendorf, Hebrock is working under the Altendorf Group umbrella.',
			nestroDescription:
				'Established in 1977 in Germany as a one-man business, Nestro – thanks to their continous development, research and expansion – is one of the top three ventilation equipment producers in the world. Besides having plentiful manufacturers throughout Europe, they also export and install their equipment all across the globe. Their production programs? Air technology, dust extraction, heating technology, waste recycling and surface treatment.',
			rojekDescription:
				"The company Rojek – founded in 1921 by Josef Rojek as a family business – is one of the oldest and best-known Czech machine manufacturers. They produce high-quality (classical) woodworking machines and first-rate wood and wood waste powered furnaces. In 1991 Jiří Rojek, the founder's grandson, rebuilt the company, and since then they are exporting their prime machines and equipment to over 65 countries.",
			robertCirok: 'Róbert Cirok',
			janosBallasa: 'János Ballasa',
			youtubeAlert: 'This feature is not available yet.'
		}
	},
	srb: {
		translation: {
			otherLanguage: 'English',
			home: 'Početna',
			machinesAndGadgets: 'Mašine i Uređaji',
			news: 'Aktuelnosti',
			contact: 'Kontakt',
			homeHeaderTypedString1: 'Iskustvo^800, posvećenost^800, pouzdanost.',
			homeHeaderTypedString2: 'The brand.',
			aboutUsTitle: 'O nama',
			aboutUsText1:
				'Nivo Sistem je osnovan kao radnja za ugradnju, servisiranje, održavanje mašina i uređaja upotrebljavanih u drvnoj industriji.',
			aboutUsText2:
				'Zaposleni u Nivo Sistemu poseduju dugogodišnje iskustvo u servisiranju uređaja kao i u poznavanju najsavremenije tehnologije. Vršimo montažu, redovno i vanredno servisiranje kant mašina, formatizera, CNC uređaja, sistema otprašivanja, kao i svih ostalih mašina. Imamo veliko iskustvo sa mašinama sledećih proizvođača:',
			aboutUsText3:
				'Veliki značaj damo na detaljnu montažu mašine, kompletnu i stručnu obuku korisnika. Dobro izmontirana mašina, perfektno obučen radnik, i redovno stručno servisirana mašina daje pouzdan kvalitet obrade i male troškove eksploatacije.',
			aboutUsText4: 'Kontaktirajte nas',
			aboutUsText5: 'i uverite se u naš moto i sami.',
			machinesAndGadgetsSubtext:
				'Pogledajte paletu naših novih mašina i potražite ponudu.',
			newsSubtext: 'Pogledajte šta je novo.',
			contactSubtext: 'Ukoliko imate pitanje, slobodno kontaktirajte nas.',
			partners: 'Naši partneri:',
			usedMachines: 'Aktuelne polovne mašine',
			noUsedMachinesAvailable: 'Trenutno nema.',
			altendorfDescription:
				"Istorija kompanije Altendorfa je istorija jedne mašine: 1906. godine Vilhelm Altendorf je konstruisao horizontalni cirkular sa kliznim stolom. Sinovi osnivača – Kurt i Vili Altendorf – su 1956. godine odlučili da fokusiraju isključivo na proizvodnju horizontalnih formatizera sa kliznim stolom odgovarajući 'Altendorf sistemu' i od tada oni postaju jedinstveni. Počinje osvajanje sveta Altendorfovog horizontalnog formatizera sa kliznim stolom. Do danas broj proizvedenih mašina premašio je brojku od 150.000 i one se koriste u stolarskim radionicama, industriji nameštaja, drvnoj industriji, autoindustriji, industriji za preradu mekih metala i plastike. Zahvaljajući kvalitetu proizvoda i strategiji međunarodnog širenja ime 'Altendorfa' je postao sinonim na tržištu horizontalnih formatizera sa kliznim stolom u više od 130 zemalja širom sveta.",
			hebrockDescription:
				'Osnovan 1980. godine u Istočnoj Vestfaliji, regiji koja se vodi kao srce nemačke industrije nameštaja. Porodična kompanija Hebrock je top proizvođač specijalizovan za kant mašine. Njihove kant mašine, počev od početnog kompaktnog i robustnog "Top 2000 plus" modela do kompletnih i potpuno opremljenih mašina "Serije K", zadovoljavaju sve najzahtevnije industrijske kriterijume za male, srednje i velike proizvođače nameštaja. Posle dugogodišnje dobro susedske saradnje sa Altendorfom, Hebrock 2018. postaje član \'Altendorf Group\' grupacija.',
			nestroDescription:
				'Nestro je osnovan 1977. u Nemačkoj kao radnja sa jednim zaposlenim. Zahvaljajući kontinualnom razvoju, unapređenju, ulaganju i širenju, Nestro je danas među prva tri najveća i najpoznatija proizvođača tehnike za sisteme otprašivanja. Danas Nestro pored Nemačke poseduje više proizvodnog pogona širom Evrope i zastupljena je sa svojim proizvodima širom sveta. Njihov proizvodni program: oprema vazdušne tehnike, oprema za sisteme otprašivanja u drvnoj, metalnoj i drugim industrijama, oprema za reciklažu raznih otpadnih materijala, oprema za loženje i oprema za lakiranje i farbanje.',
			rojekDescription:
				"Češka kompanija Rojek je osnovan 1921. godine od strane osnivača Josefa Rojeka kao porodična firma. Rojek je jedna od najstarijih i najpoznatijih čeških proizvođača mašina za drvnu industriju. Oni proizvode visokokvalitetne (klasične) stolarske mašine u dve serije – 'Euro' i 'Industry' seriji – kao i prvorazredne kotlove na drvo i na drvno-industrijski otpad. Kompanija je ponovo pokrenuta 1991. godine od strane unuke osnivača, tj. Jiri Rojeka. Od tada izvoze, prodavaju i ugrađuju svoje mašine i uređaje više od 65 zemalja širom sveta.",
			robertCirok: 'Robert Cirok',
			janosBallasa: 'Janoš Balaša',
			youtubeAlert: 'Ova funkcija još nije dostupna.'
		}
	}
}

i18n
	.use(initReactI18next) // passes i18n down to react-i18next
	.init({
		resources,
		lng: 'srb',

		keySeparator: false, // we do not use keys in form messages.welcome

		interpolation: {
			escapeValue: false // react already safes from xss
		}
	})

export default i18n

import React from 'react'
import { Container } from 'react-bootstrap'

const Header = props => (
	<div>
		<Container
			fluid
			className="hero-image"
			style={{
				backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.75)), url(${
					props.image
				})`,
				backgroundPosition: props.backgroundPosition,
				marginBottom: 80
			}}>
			<div className="hero-text">
				<h1 style={{ fontSize: `${props.fontSizePercent}%` }}>{props.title}</h1>
				<hr />
				<p>{props.subtext}</p>
			</div>
		</Container>
	</div>
)

export default Header

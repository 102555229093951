import React from 'react'
import { withTranslation } from 'react-i18next'
import { NavLink } from 'react-router-dom'
import { Navbar, Nav } from 'react-bootstrap'

class NavigationBar extends React.Component {
	state = {
		slide: 0,
		lastScrollY: 0,
		isExpanded: false
	}

	constructor(props) {
		super(props)
		this.handleScroll = this.handleScroll.bind(this)
		this.handleToggle = this.handleToggle.bind(this)
		this.handleNavItemClick = this.handleNavItemClick.bind(this)
		this.handlgeLanguageChange = this.handlgeLanguageChange.bind(this)
	}

	componentWillMount() {
		window.addEventListener('scroll', this.handleScroll)
	}

	componentWillUnmount() {
		window.removeEventListener('scroll', this.handleScroll)
	}

	handleScroll = () => {
		const { lastScrollY } = this.state
		const currentScrollY = window.scrollY

		if (currentScrollY > 100 && currentScrollY > lastScrollY) {
			this.setState({ slide: '-56px' })
		} else {
			this.setState({ slide: '0px' })
		}

		this.setState({ lastScrollY: currentScrollY })
	}

	handleToggle = () => {
		this.setState({ isExpanded: !this.state.isExpanded })
	}

	handleNavItemClick = () => {
		this.setState({ isExpanded: false })
	}

	handlgeLanguageChange = () => {
		const { t, i18n } = this.props
		if (t('otherLanguage') === 'English') {
			i18n.changeLanguage('eng')
		} else {
			i18n.changeLanguage('srb')
		}
		this.handleNavItemClick()
	}

	render() {
		const { isExpanded } = this.state
		const { t } = this.props
		return (
			<Navbar
				expanded={isExpanded}
				onToggle={this.handleToggle}
				fixed="top"
				bg="dark"
				variant="dark"
				expand="lg"
				style={{
					transform: `translate(0, ${this.state.slide})`,
					transition: 'transform 150ms linear'
				}}>
				<NavLink exact to="/" className="navbar-brand disable-focus-outline">
					Nivo Sistem
				</NavLink>
				<Navbar.Toggle
					aria-controls="basic-navbar-nav"
					className="disable-focus-outline"
				/>
				<Navbar.Collapse id="basic-navbar-nav" className="justify-content-end">
					<Nav id="basic-navbar-nav" className="justify-content-end">
						<Nav.Item>
							<NavLink
								exact
								to="/"
								className="nav-link disable-focus-outline"
								onClick={this.handleNavItemClick}>
								{t('home')}
							</NavLink>
						</Nav.Item>
						<Nav.Item>
							<NavLink
								to="/machines-and-gadgets"
								className="nav-link disable-focus-outline"
								onClick={this.handleNavItemClick}>
								{t('machinesAndGadgets')}
							</NavLink>
						</Nav.Item>
						<Nav.Item>
							<NavLink
								to="/news"
								className="nav-link disable-focus-outline"
								onClick={this.handleNavItemClick}>
								{t('news')}
							</NavLink>
						</Nav.Item>
						<Nav.Item>
							<NavLink
								to="/contact"
								className="nav-link disable-focus-outline"
								onClick={this.handleNavItemClick}>
								{t('contact')}
							</NavLink>
						</Nav.Item>
						<Nav.Item
							className="nav-link disable-focus-outline"
							style={{ cursor: 'pointer' }}
							onClick={this.handlgeLanguageChange}>
							{t('otherLanguage')}
						</Nav.Item>
					</Nav>
				</Navbar.Collapse>
			</Navbar>
		)
	}
}

export default withTranslation()(NavigationBar)

import React from 'react'
import { withTranslation } from 'react-i18next'

const AboutUs = ({ t, i18n, history }) => (
	<div
		id="about-us"
		style={{ color: 'rgb(100,109,111)', fontSize: '1.5em', paddingTop: 80 }}>
		<h1>{t('aboutUsTitle')}</h1>
		<div style={{ paddingLeft: 25, marginTop: 25 }}>
			<p>{t('aboutUsText1')}</p>
			<p>
				{`${t('aboutUsText2')} `}
				<span style={{ color: 'rgb(71, 80, 82)' }}>
					Putsch Meniconi, HolzHer, Striebig, Harwi, Cehisa, Brandt, Casaday,
					Hebrock, Altendorf, Rojek, Sicar, Casolin, OTT, Houfek, Bimatic,
					Scheer, Nestro, Barucca, Holzma.
				</span>
				{` ${t('aboutUsText3')}`}
			</p>
			<p>
				<span
					style={{ cursor: 'pointer', textDecoration: 'underline' }}
					onClick={() => history.push(`/contact`)}>
					{t('aboutUsText4')}
				</span>
				{` ${t('aboutUsText5')}`}
			</p>
		</div>
	</div>
)

export default withTranslation()(AboutUs)

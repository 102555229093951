import React from 'react'
import { withTranslation } from 'react-i18next'
import { Row, Col, ToggleButtonGroup, ToggleButton } from 'react-bootstrap'

/*
aktuelne polovne masine
available used machines || used machines available
*/

const ToggleSwitch = props => (
	<Row className="justify-content-xs-center">
		<Col />
		<Col xs="auto">
			<ToggleButtonGroup
				type="radio"
				name="news-or-used-machines"
				defaultValue="news"
				onChange={props.handleToggle}>
				<ToggleButton
					variant={props.newsSelected ? 'primary' : 'outline-primary'}
					value="news"
					className="shadow-none toggle-btn">
					{props.t('news')}
				</ToggleButton>
				<ToggleButton
					variant={props.newsSelected ? 'outline-primary' : 'primary'}
					value="used-machines"
					className="shadow-none toggle-btn">
					{props.t('usedMachines')}
				</ToggleButton>
			</ToggleButtonGroup>
		</Col>
		<Col />
	</Row>
)
export default withTranslation()(ToggleSwitch)

import React from 'react'
import CompanyWall2022 from '../../pics/company-wall-bonitet-2022.png'

const Certificate = () => (
		<img
			className="certificate"
			onClick={() => window.open('https://www.companywall.rs/', '_blank')}
			src={CompanyWall2022}
			alt="CompanyWall bonitet 2022"
		/>
)

export default Certificate
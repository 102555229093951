import React from 'react'
import { Row, Col } from 'react-bootstrap'
import { FaPhone, FaEnvelope } from 'react-icons/fa'

const PersonalContact = props => (
	<Row className="personal-contact">
		<Col sm={6} className="center-container">
			<img
				src={props.person.pic}
				style={{ width: '90%', borderRadius: 25 }}
				alt=""
			/>
		</Col>
		<Col sm={6} className="center-container">
			<div>
				<h2>
					<strong>{props.person.name}</strong>
				</h2>
				<hr />
				<h5>
					<FaPhone /> {props.person.phone}
					<br />
					<FaEnvelope /> <span>{`${props.person.email}@ nivosistem.rs`}</span>
				</h5>
			</div>
		</Col>
	</Row>
)

export default PersonalContact

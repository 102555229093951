import React from 'react'
import { Row, Col } from 'react-bootstrap'
import { TwitterTimelineEmbed } from 'react-twitter-embed'

const NewsBody = props => (
	<Row>
		<Col xs={12} sm={{ span: 10, offset: 1 }} lg={{ span: 6, offset: 3 }}>
			<TwitterTimelineEmbed sourceType="profile" screenName="NivoSistem" />
		</Col>
	</Row>
)

export default NewsBody

import React from 'react'
import { withTranslation } from 'react-i18next'
import { Container } from 'react-bootstrap'
import Divider from './Divider.js'
import HomeHeader from './home/HomeHeader.js'
import AboutUs from './home/AboutUs.js'
import Certificate from './home/Certificate.js'
import Shortcut from './home/Shortcut.js'
import SocialMedia from './home/SocialMedia.js'
import Partners from './home/Partners.js'
import NewsShortcutPic from '../pics/news-header.png'
import MachinesAndGadgetsShortcutPic from '../pics/machines-and-gadgets-header.JPG'
import ContactShortcutPic from '../pics/contact-header.JPG'

class Home extends React.Component {
	render() {
		const { history, t } = this.props
		const shortcuts = [
			{
				id: 'news',
				title: t('news'),
				subtext: t('newsSubtext'),
				image: NewsShortcutPic
			},
			{
				id: 'contact',
				title: t('contact'),
				subtext: t('contactSubtext'),
				image: ContactShortcutPic
			},
			{
				id: 'machines-and-gadgets',
				title: t('machinesAndGadgets'),
				subtext: t('machinesAndGadgetsSubtext'),
				image: MachinesAndGadgetsShortcutPic
			}
		]
		return (
			<div>
				<HomeHeader />

				<Container>
					<AboutUs history={history} />

					<Certificate />

					<Divider />

					<div id="home-shortcuts">
						{shortcuts.map(shortcut => (
							<Shortcut
								key={shortcut.id}
								shortcut={shortcut}
								history={history}
							/>
						))}
						<SocialMedia youtubeAlert={t('youtubeAlert')} />
					</div>

					<Divider />
				</Container>

				<Partners />
			</div>
		)
	}
}

export default withTranslation()(Home)

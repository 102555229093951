import React from 'react'
import { withTranslation } from 'react-i18next'
import { Container } from 'react-bootstrap'
import Divider from './Divider.js'
import Header from './Header.js'
import NivoSistemContact from './contact/NivoSistemContact.js'
import PersonalContact from './contact/PersonalContact.js'
import HeaderImage from '../pics/contact-header.JPG'
import ProfileRobert from '../pics/profile_robert.JPG'
import ProfileJanos from '../pics/profile_janos.JPG'

const Contact = ({ t }) => (
	<div>
		<Header
			image={HeaderImage}
			title={t('contact')}
			subtext={t('contactSubtext')}
			fontSizePercent={500}
		/>

		<Container>
			<NivoSistemContact />

			<Divider />

			<PersonalContact
				person={{
					pic: ProfileRobert,
					name: t('robertCirok'),
					phone: '+381 (0)62 563 742',
					email: 'robert'
				}}
			/>
			<br />
			<PersonalContact
				person={{
					pic: ProfileJanos,
					name: t('janosBallasa'),
					phone: '+381 (0)62 639 641',
					email: 'janos'
				}}
			/>
		</Container>
	</div>
)

export default withTranslation()(Contact)

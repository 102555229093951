import React from 'react'
import { withTranslation } from 'react-i18next'
import { Container } from 'react-bootstrap'
import Header from './Header.js'
import ToggleSwitch from './news/ToggleSwitch.js'
import NewsBody from './news/NewsBody.js'
import UsedMachines from './news/UsedMachines.js'
import HeaderImage from '../pics/news-header.png'

class News extends React.Component {
	state = { newsSelected: true }

	constructor(props) {
		super(props)
		this.handleToggle = this.handleToggle.bind(this)
	}

	handleToggle = selected => {
		const newsSelected = selected === 'news'
		this.setState({ newsSelected })
	}

	render() {
		const { newsSelected } = this.state
		const { t } = this.props
		return (
			<div>
				<Header
					image={HeaderImage}
					title={t('news')}
					subtext={t('newsSubtext')}
					fontSizePercent={400}
				/>

				<Container fluid style={{ marginTop: -50 }}>
					<ToggleSwitch
						handleToggle={this.handleToggle}
						newsSelected={newsSelected}
					/>

					<br />

					{newsSelected ? <NewsBody /> : <UsedMachines />}
				</Container>
			</div>
		)
	}
}

export default withTranslation()(News)

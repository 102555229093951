import React from 'react';

const Footer = () => (
  <footer style={{ width: '100%', textAlign: 'center', marginTop: 200 }}>
    <hr width='75%' />
    <p style={{ fontSize: 18, color: 'rgb(149, 149, 149)' }}>
      Copyright © 2023 Nivo Sistem
    </p>
  </footer>
);

export default Footer;

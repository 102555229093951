import React from 'react'
import { withTranslation } from 'react-i18next'
import { Container } from 'react-bootstrap'
import { FaChevronDown } from 'react-icons/fa'
import TypedHeading from './TypedHeading.js'

const arrowSize = 72

const HomeHeader = ({ t, i18n }) => (
	<Container fluid id="home-header-container">
		<TypedHeading
			strings={[
				t('homeHeaderTypedString1'),
				t('homeHeaderTypedString2'),
				'Nivo Sistem.'
			]}
			currentLang={i18n.language}
		/>
		<FaChevronDown
			size={arrowSize}
			style={{
				position: 'absolute',
				left: `calc(50% - ${arrowSize / 2}px)`,
				bottom: 0,
				color: 'rgba(255,255,255,0.25)',
				cursor: 'pointer'
			}}
			onClick={() =>
				document
					.getElementById('about-us')
					.scrollIntoView({ behavior: 'smooth', block: 'start' })
			}
		/>
	</Container>
)

export default withTranslation()(HomeHeader)

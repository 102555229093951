import React from 'react'
import NSLogo from '../pics/logo.png'

const logoSize = 35
const logoPadding = 15

const Divider = () => (
	<div style={{ position: 'relative', width: '100%', margin: '80px 0' }}>
		<hr style={{ borderTop: '2px solid rgba(0, 0, 0, 0.25)' }} />
		<div
			style={{
				position: 'absolute',
				top: `-${logoSize / 2}px`,
				left: `calc(50% - ${logoSize / 2}px - ${logoPadding}px)`,
				padding: `0 ${logoPadding}px`,
				backgroundColor: 'white'
			}}>
			<img
				src={NSLogo}
				alt=""
				style={{
					width: logoSize
				}}
			/>
		</div>
	</div>
)

export default Divider

import React from 'react'
import Typed from 'typed.js'

class TypedHeading extends React.Component {
	constructor(props) {
		super(props)
		this.state = { prevLang: this.props.currentLang }
	}

	componentDidMount() {
		const { strings } = this.props

		const options = {
			strings: strings,
			typeSpeed: 55,
			backSpeed: 35,
			backDelay: 1000
		}

		this.typed = new Typed(this.el, options)
	}

	componentWillUnmount() {
		this.typed.destroy()
	}

	render() {
		return (
			<div id="home-header-title">
				<div
					ref={el => {
						this.el = el
					}}
				/>
			</div>
		)
	}
}

export default TypedHeading

import React from 'react'
import { Row, Col } from 'react-bootstrap'
import Logo from '../../pics/logo-extended.png'

const NivoSistemContact = () => (
	<Row>
		<Col className="ns-contact">
			<img className="nivoSistemLogo" src={Logo} alt="Nivo Sistem" />
			<br />
			Aranj Janoša 5.
			<br />
			24300 Bačka Topola
		</Col>
	</Row>
)

export default NivoSistemContact

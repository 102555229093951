import React from 'react'
import { Row } from 'react-bootstrap'
import File from './File.js'

const CompanyInfo = ({ company }) => (
	<div className="company-info-container">
		<Row className="company-info">
			<div
				onClick={() => window.open(company.website, '_blank')}
				style={{
					background: `white url(${company.logo}) no-repeat ${
						company.logoOffsetX
					} center`,
					backgroundSize: '90%'
				}}
				className="company-info-logo"
			/>
			<h2 style={{ fontSize: 42 }}>{company.name}</h2>
			<p style={{ fontSize: 20 }}>{company.description}</p>
			{company.attachments.map(file => <File key={file.name} file={file} />)}
		</Row>
	</div>
)

export default CompanyInfo

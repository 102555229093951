import React from 'react'
import { HashRouter, Route, Switch, Redirect } from 'react-router-dom'
import 'bootstrap/dist/css/bootstrap.css'
import './style.scss'
import './style.css'
import ScrollToTop from './components/ScrollToTop.js'
import NavigationBar from './components/NavigationBar.js'
import Footer from './components/Footer.js'
import Home from './components/Home.js'
import MachinesAndGadgets from './components/MachinesAndGadgets.js'
import News from './components/News.js'
import Contact from './components/Contact.js'

function App() {
	return (
		<HashRouter hashType="noslash">
			<ScrollToTop>
				<NavigationBar />

				<div style={{ marginTop: 56 }}>
					<Switch>
						<Route path="/" exact component={Home} />
						<Route
							path="/machines-and-gadgets"
							component={MachinesAndGadgets}
						/>
						<Route path="/news" component={News} />
						<Route path="/contact" component={Contact} />
						<Route render={() => <Redirect to="/" />} />
					</Switch>
				</div>

				<Footer />
			</ScrollToTop>
		</HashRouter>
	)
}

export default App

import React from 'react';
import { withTranslation } from 'react-i18next';
import { Container } from 'react-bootstrap';
import Header from './Header.js';
import HeaderImage from '../pics/machines-and-gadgets-header.JPG';
import CompanyInfo from './machines-and-gadgets/CompanyInfo.js';
import AltendorfLogoTest from '../pics/logos/logo_altendorf_small.png';
import HebrockLogoTest from '../pics/logos/logo_hebrock.png';
import NestroLogoTest from '../pics/logos/logo_nestro.jpg';
import RojekLogoTest from '../pics/logos/logo_rojek.png';
import Altendorf_WA_6_Brochure_gb_20170105 from '../attachments/altendorf/20170105_Altendorf_WA_6_Brochure_gb.pdf';
import Altendorf_F_45_Prospekt_gb from '../attachments/altendorf/Altendorf_F_45_Prospekt_gb.pdf';
import Altendorf_WA_80_Brochure_English from '../attachments/altendorf/Altendorf_WA_80_Brochure_English.pdf';
import Altendorf_F_25 from '../attachments/altendorf/F_25_brochure_04_2020_compressed.pdf';
import Hebrock_F_and_K_series_next from '../attachments/hebrock/New_K_F_Series-next_Broschuere_EN.pdf';
import Hebrock_brochure_F_series_en from '../attachments/hebrock/_Hebrock_brochure_F_series_en.pdf';
import Hebrock_brochure_K_series_en from '../attachments/hebrock/_Hebrock_brochure_K_series_en.pdf';
import Hebrock_brochure_top2000plus_en from '../attachments/hebrock/_Hebrock_brochure_top2000plus_en.pdf';
import Katalog_ROJEK_compressed from '../attachments/rojek/Katalog_ROJEK_compressed.pdf';

const MachinesAndGadgets = ({ t }) => {
  const companies = [
    {
      name: 'Altendorf',
      logo: AltendorfLogoTest,
      logoOffsetX: '13px',
      website: 'https://www.altendorf.com/en/home.html',
      description: t('altendorfDescription'),
      attachments: [
        {
          name: 'F 25',
          source: Altendorf_F_25,
          fileType: 'pdf',
        },
        {
          name: 'F 45',
          source: Altendorf_F_45_Prospekt_gb,
          fileType: 'pdf',
        },
        {
          name: 'WA 6',
          source: Altendorf_WA_6_Brochure_gb_20170105,
          fileType: 'pdf',
        },
        {
          name: 'WA 80',
          source: Altendorf_WA_80_Brochure_English,
          fileType: 'pdf',
        },
      ],
    },
    {
      name: 'Hebrock',
      logo: HebrockLogoTest,
      logoOffsetX: 'center',
      website: 'https://www.hebrock.de/en/',
      description: t('hebrockDescription'),
      attachments: [
        {
          name: 'F & K series NEXT',
          source: Hebrock_F_and_K_series_next,
          fileType: 'pdf',
        },
        {
          name: 'F series',
          source: Hebrock_brochure_F_series_en,
          fileType: 'pdf',
        },
        {
          name: 'K series',
          source: Hebrock_brochure_K_series_en,
          fileType: 'pdf',
        },
        {
          name: 'Top 2000 plus',
          source: Hebrock_brochure_top2000plus_en,
          fileType: 'pdf',
        },
      ],
    },
    {
      name: 'Nestro',
      logo: NestroLogoTest,
      logoOffsetX: '13px',
      website: 'https://www.nestro.com/en/',
      description: t('nestroDescription'),
      attachments: [],
    },
    {
      name: 'Rojek',
      logo: RojekLogoTest,
      logoOffsetX: 'center',
      website: 'http://www.rojek.cz/rojek.asp?jazyk=uk',
      description: t('rojekDescription'),
      attachments: [
        {
          name: 'Katalog 2020',
          source: Katalog_ROJEK_compressed,
          fileType: 'pdf',
        },
      ],
    },
  ];
  return (
    <div>
      <Header
        image={HeaderImage}
        title={t('machinesAndGadgets')}
        subtext={t('machinesAndGadgetsSubtext')}
        fontSizePercent={425}
        backgroundPosition='0% 160%'
      />

      <Container>
        {companies.map(company => (
          <CompanyInfo key={company.name} company={company} />
        ))}
      </Container>
    </div>
  );
};

export default withTranslation()(MachinesAndGadgets);

import React from 'react'
import { withTranslation } from 'react-i18next'
import { Container } from 'react-bootstrap'
import AltendorfLogo from '../../pics/logos/logo_altendorf.png'
import HebrockLogo from '../../pics/logos/logo_hebrock.png'
import NestroLogo from '../../pics/logos/logo_nestro.jpg'
import RojekLogo from '../../pics/logos/logo_rojek.png'

const partners = [
	{
		name: 'Altendorf',
		website: 'https://www.altendorf.com/en/home.html',
		logo: AltendorfLogo
	},
	{
		name: 'Hebrock',
		website: 'https://www.hebrock.de/en/',
		logo: HebrockLogo
	},
	{
		name: 'Nestro',
		website: 'https://www.nestro.com/en/',
		logo: NestroLogo
	},
	{
		name: 'Rojek',
		website: 'http://www.rojek.cz/rojek.asp?jazyk=uk',
		logo: RojekLogo
	}
]

const Partners = ({ t }) => (
	<div>
		<Container style={{ color: 'rgb(100, 109, 111)', marginBottom: 25 }}>
			<h2>{t('partners')}</h2>
		</Container>
		<div id="partners-container">
			{partners.map(partner => (
				<div style={{ marginTop: 25 }} key={partner.name}>
					<a href={partner.website} target="_blank" rel="noopener noreferrer">
						<img
							src={partner.logo}
							className="partner-logo"
							alt={partner.name}
						/>
					</a>
				</div>
			))}
		</div>
	</div>
)

export default withTranslation()(Partners)

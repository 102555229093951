import React from 'react'
import { withTranslation } from 'react-i18next'
import { Container } from 'react-bootstrap'
import Machine from './Machine.js'
import Divider from '../Divider.js'

const usedMachines = []

/*
	'usedMachine' example:
	import TestPic1 from '../../pics/test-pic-1.jpg'
	{
		name: 'Hebrock 5700f',
		description:
			'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
		pics: [TestPic1, TestPic2]
	}
*/

const UsedMachines = ({ t }) => (
	<Container style={{ marginTop: 25 }}>
		{usedMachines.length > 0 ? (
			usedMachines.map((machine, index) => (
				<div>
					<Machine key={machine.name} machine={machine} />
					{index < usedMachines.length - 1 ? <Divider /> : <br />}
				</div>
			))
		) : (
			<div style={{ fontSize: 24 }}>{t('noUsedMachinesAvailable')}</div>
		)}
	</Container>
)

export default withTranslation()(UsedMachines)
